import React from 'react'

import SiteLayout from '../layout/SiteLayout'
import SEO from '../components/SEO'
import Container from '../components/Container'
import { Title, TitleContainer } from '../components/Title'

const TermsOfUse = () => (
  <SiteLayout>
    <SEO title="Terms of use" />
    <TitleContainer>
      <Title>Privacy Policy</Title>
    </TitleContainer>
    <Container>
      <section className="terms">
        <h1>Privacy Policy</h1>
        <p>
          Air Accounting Limited (AirAccounting) understands that your privacy
          is important to you and that you care about how your personal data is
          used. We respect your privacy and your rights to control your personal
          data (data). We will always protect your data, be clear about the data
          we collect from you and the reasons why. We do not and will not sell
          your data to third parties.
        </p>
        <p>
          Please read this Privacy Policy carefully and ensure that you
          understand it.{' '}
        </p>
        <ol>
          <li>
            Information About Us
            <div>
              <p>
                Air Accounting Limited (AirAccounting) is a company registered
                in England and Wales under company number 10256980.
              </p>
              <p>
                Registered address: Dudley Court South, The Waterfront, Brierley
                Hill, West Midlands, DY5 1XN.
              </p>
              <p>Data Protection Officer: Adam Mercy</p>
              <div className="ml-6">
                <p>
                  Email address:{' '}
                  <a href="mailto:data@airaccounting.com">
                    data@airaccounting.com
                  </a>
                </p>
                <p>Telephone number: 01384 906 007</p>
                <p>
                  Postal address: Dudley Court South, The Waterfront, Brierley
                  Hill, DY5 1XN
                </p>
              </div>
              <p>
                We are a controller for the purposes of the General Data
                Protection Regulation (Regulation (EU) 2016/679).
              </p>
            </div>
          </li>
          <li>
            What Does This Policy Cover?
            <div>
              <p>
                This Privacy Policy (the ‘Policy’) explains the data we collect
                from you either directly or indirectly, why and how we use it.
                It explains how we protect your data, the choices you can make
                about the data we collect and how you can control these
                decisions.
              </p>
              <p>
                We will only use your data when the law allows us to. Most
                commonly, we will use your data in the following circumstances:
              </p>
              <ul>
                <li>
                  Where it is necessary for our legitimate business interests
                  (or those of a third party) and your interests and fundamental
                  rights do not override those interests
                </li>
                <li>
                  Where we need to perform the contract for services we are
                  about to enter into or have entered into with you
                </li>
                <li>
                  Where we need to comply with a legal or regulatory obligation.
                </li>
              </ul>
              <p>This Policy describes:</p>
              <ul>
                <li>What is Personal Data</li>
                <li>Types of data we collect, why and how</li>
                <li>How we use your data</li>
                <li>When we may share or disclose your data</li>
                <li>How to access and control your data</li>
                <li>Communication, marketing and advertising preferences</li>
                <li>How and where we store and process data</li>
                <li>How we secure your data</li>
                <li>Our retention of your data</li>
                <li>Your rights</li>
                <li>Changes to our Policy</li>
              </ul>
            </div>
          </li>
          <li>
            What is Personal Data?
            <div>
              <p>
                Personal data is defined by the General Data Protection
                Regulation (EU Regulation 2016/679) (the “GDPR”) and the Data
                Protection Act 2018 (collectively, “the Data Protection
                Legislation”) as ‘any information relating to an identifiable
                person who can be directly or indirectly identified in
                particular by reference to an identifier’.
              </p>
              <p>
                Personal data is, in simpler terms, any information about you
                that enables you to be identified. Personal data covers obvious
                information such as your name and contact details, but it also
                covers less obvious information such as identification numbers,
                electronic location data, and other online identifiers.
              </p>
            </div>
          </li>
          <li>
            Types of Data We Collect, Why and How
            <div>
              <p>
                We will only collect personal data from you that we consider
                necessary in the context and purpose in which it’s given.
              </p>
              <p>
                Through your use of our services we may also collect personal
                data from you about someone else. If you provide us with
                personal data about someone else, you must ensure that you are
                authorised to disclose that data to us and that, without us
                taking any further steps required by applicable data protection
                laws or privacy laws, we may collect, use and disclose such
                personal data for the purposes described in the Policy. You
                must, therefore, take reasonable steps to ensure that the third
                party concerned is aware of and consents to the various matters
                detailed in this Policy, including: the fact that their personal
                data is being collected; the purposes of which that data is
                being collected; the intended recipients of that data; and the
                third party’s right to obtain access to the data (including
                details of how to request access). Where requested to do so, you
                must assist us with any requests by the third party to access or
                update the personal data you have collected from them and
                provided in connection with our Services.{' '}
              </p>
              <p>You may be required to provide information when:</p>
              <ul>
                <li>Learning about AirAccounting</li>
                <li>Forming a company</li>
                <li>Purchasing, or enquiring about a service</li>
                <li>Subscribing to an AirAccounting Service</li>
                <li>Using our services</li>
              </ul>
              <h3>Learning about AirAccounting</h3>
              <p>
                In order to gain more information about AirAccounting, you may
                visit our website, www.airaccounting.com or landing pages, speak
                to one of our support advisors or request and take out a demo of
                our accounting software. At these times you may voluntarily
                provide information about yourself.
              </p>
              <p>
                <strong>We collect:</strong> The type of personal data collected
                may include, full name; phone numbers; email addresses; business
                type; business name; usage data and statistics; connection data;
                other unique identifiers such as IP addresses or device ID’s,
                marketing and advertising responses and preferences.
              </p>
              <p>
                <strong>Why:</strong> To make sure you find the information you
                are looking for and for us to make recommendations to you about
                our products and services. To follow up your enquiry, provide
                you with access to business guides, to share news about events
                and networking opportunities. Additionally, your IP address
                helps us to understand geographic information about our website
                users.
              </p>
              <p>
                <strong>How:</strong> Data is collected through online forms;
                surveys; email; chat; telephone, or in person.
              </p>
              <h3>Forming a Limited Company</h3>
              <p>
                If you require AirAccounting to form a limited company on your
                behalf you will be asked to provide information relating to
                yourself, the limited company you want to form and related third
                parties such as additional directors or shareholders, if you
                have any. This information is needed so your company can be
                registered with Companies House.{' '}
              </p>
              <p>
                <strong>We collect:</strong> The type of personal data collected
                may include, full name; home/business postal address, phone
                numbers; email addresses; registered office/service address;
                date of birth; title; nationality; company details, to include
                share split, person of significant control; authentication data,
                for example passport number, national insurance number; place of
                birth; payment card information; usage data and statistics;
                connection data; other unique identifiers such as IP addresses
                or device IDs, marketing and advertising responses and
                preferences; purchase inquiry and history; products, services or
                content provided.
              </p>
              <p>
                <strong>Why:</strong> To check company name availability and
                ensure the company is formed correctly with the correct company
                officers and company information. To comply with legal and
                regulatory compliance, to include crime prevention. To provide
                the right guidance, answer your questions and to complete the
                transaction.
              </p>
              <p>
                <strong>How:</strong> Data is collected through online forms;
                email, chat, telephone or in person.
              </p>
              <h3>Purchasing, or Enquiring about an AirAccounting Service</h3>
              <p>
                Depending on the type of service you purchase from us you may be
                asked to share information so we can provide a quote, respond to
                your enquiry, determine the service best suited for you and to
                complete the transaction.
              </p>
              <p>
                <strong>We collect:</strong> The type of personal data collected
                may include; full name; home/business postal address; phone
                numbers; email addresses; payment card information; business
                type/name/trade; usage data and statistics; connection data;
                other unique identifiers such as IP addresses or device IDs;
                marketing and advertising responses and preferences; purchase
                inquiry and history; products, services or content provided.
              </p>
              <p>
                <strong>Why:</strong> To carry out our obligations to provide
                services you have engaged us to deliver or to perform. To
                provide a quote, form policy documents, complete the transaction
                and to follow up your purchase or enquiry. Please note we do not
                store any card payment data and only use payment information in
                connection with the purchase made.
              </p>
              <p>
                <strong>How:</strong> Data is collected through online forms;
                email; chat; or telephone.
              </p>
              <h3>Subscribing to an AirAccounting Service</h3>
              <p>
                If you subscribe to one of our accounting services, you may be
                asked to provide information relating to you/or your business.
                The information required will depend on the type of service you
                are subscribing to. You may also be asked to provide information
                about third parties, for example, additional employees,
                directors or shareholders. We may also seek your permission to
                contact external third parties to gain items such as
                professional clearance or assignment schedules.
              </p>
              <p>
                <strong>We collect:</strong> The type of personal data collected
                may include, full name; home/business postal address; registered
                office address; business name/type; phone numbers; email
                addresses; job title; date of birth; place of birth; title;
                nationality; marital status; national insurance number; tax
                codes; HMRC UTR numbers; government issued identification,
                including passport numbers; sanction and watch list data;
                details of any other business involvement including
                shareholding; payment card information; bank account data; usage
                data and statistics; connection data; other unique identifiers
                such as IP addresses or device IDs, marketing and advertising
                responses and preferences; username and passwords for accessing
                and using our services, purchase inquiry and history; products,
                services or content provided.
              </p>
              <p>
                <strong>Why:</strong> To verify your identity for
                legal/regulatory compliance and crime prevention. To carry out
                our obligations to provide services you have engaged us to
                deliver or to perform. To configure your accounting/payroll
                software correctly and provide the right support and advice.
                Analysis for management and marketing purposes.
              </p>
              <p>
                <strong>How:</strong> Data is collected through, online forms;
                surveys; email; chat; telephone, post or in person.
              </p>
              <h3>Using our accounting services</h3>
              <p>
                During the course of your subscription, you may be asked to
                provide additional information and to keep us informed of any
                changes to your circumstances or that of your business. You may
                also require additional services or products.
              </p>
              <p>
                <strong>We collect:</strong> The type of personal data collected
                may include changes in marital status; changes of home/business
                postal address; other sources of income; investment activities;
                changes of your company structure, to include changes to company
                officers; usage data and statistics; connection data; other
                unique identifiers such as IP addresses or device IDs, marketing
                and advertising purposes and preferences; username and passwords
                for accessing and using our services, purchase inquiry and
                history; products, services or content provided.
              </p>
              <p>
                <strong>Why:</strong> To carry out our obligations to provide
                services you have engaged us to deliver or to perform. Support
                you in managing your affairs, provide the right guidance and
                support at the right time, ensure you continue to be subscribed
                to the right service for you, to notify you about changes to our
                services, make suggestions/recommendations to you about other
                products and services. For related purposes such as updating and
                enhancing our client records, improving our services,
                troubleshooting, testing, data analysis, research, profiling,
                statistical and survey purposes. Analysis for management and
                marketing purposes and statutory returns, legal and regulatory
                compliance and crime prevention.
              </p>
              <p>
                <strong>How:</strong> Data is collected through, online forms;
                surveys; emails; chat; telephone; SMS text message; post or in
                person.
              </p>
            </div>
          </li>
          <li>
            How Do You Use My Personal Data?
            <div>
              <p>
                AirAccounting also uses the data we collect to provide you with
                services and to communicate with you.
              </p>
              <p>AirAccounting uses the data for the following purposes:</p>
              <ul>
                <li>Business Operations</li>
                <li>Communication, Marketing and Advertising</li>
                <li>Compliance</li>
                <li>Providing and enhancing our products and services</li>
                <li>Security, Safety and Dispute Resolution</li>
              </ul>
              <h3>Business Operations</h3>
              <p>
                We use data to run business operations to provide you with the
                services, profile our user base and develop anonymised aggregate
                analysis and business intelligence that enables us to operate,
                protect, make informed decisions and report on the performance
                of our business.
              </p>
              <h3>Communication, Marketing and Advertising</h3>
              <p>
                We use data we collect to deliver and personalise our
                communication with you.
              </p>
              <p>
                As an example, we may contact you by email or other means of
                electronic communication to inform you about our services,
                invite you to take part in a survey, business activities and
                changes to our services.
              </p>
              <p>
                If you subscribe to or purchase one of our services, you will
                also receive service-related communications. These will include
                system and service support communications, policy, security or
                software updates.
              </p>
              <p>
                We also use cookies and similar technologies to provide the most
                relevant products and information to you.
              </p>
              <h3>Compliance</h3>
              <p>
                We use data to ensure we comply with relevant regulation and
                law. This includes needing to verify your identify if you
                subscribe to our accounting services. The verification involves:
              </p>
              <ol className="letters">
                <li>
                  a full electoral roll search (your consent is not required for
                  this); and
                </li>
                <li>
                  credit reference agencies placing a search (soft footprint) on
                  your electronic file and your data being accessed by third
                  parties for the specific purpose of anti-money laundering,
                  credit assessment, ID verification, debt collection, asset
                  reunification, tracing and fraud prevention.
                </li>
              </ol>
              <h3>Providing and enhancing our products and services</h3>
              <p>
                We use data to provide and improve our services and perform
                essential business operations. This includes operating our
                services, maintaining and improving the performance of our
                products and services, including developing new features,
                research, testing and providing client support. We may also make
                aggregated anonymised information publicly available on our
                website.
              </p>
              <h3>Security, Safety, and Dispute Resolution</h3>
              <p>
                We use data to protect the security and safety of our products
                and services and our clients, to detect and prevent fraud, to
                resolve disputes and enforce our agreements.
              </p>
              <p>
                Outgoing and incoming telephone calls to our advisory and
                support teams are recorded for training and monitoring purposes.
                This process helps us to maintain high standards of service and
                resolve issues.
              </p>
              <p>
                In some circumstances, where permitted or required by law, we
                may process your personal data without your knowledge or
                consent. This will only be done within the bounds of the Data
                Protection Legislation and your legal rights.
              </p>
            </div>
          </li>
          <li>
            Do You Share or Disclose My Personal Data?
            <div>
              <p>
                We do not sell data about our clients and we only share or
                disclose your data as authorised in this Policy.
              </p>
              <p>
                We may share or disclose data with the following types of third
                parties.
              </p>
              <p>
                <strong>Affiliates/Partners:</strong> Our sites may from time to
                time contain links to and from the website of other partner
                networks and affiliates who provide complementary services. Your
                information will only be passed to the relevant service provider
                if you express interest in a third party service by completing a
                form on our website or speaking with an AirAccounting team
                member. These third parties may then send communications to you
                and provide information on offers and services that may be of
                interest to you. If at any time you wish to opt out of further
                communications, please follow the instructions in their
                marketing communications or consult their own privacy policies.
                Please note that some of our services are provided by third
                parties, whose privacy practices may differ from AirAccounting.
                If you provide personal data to any of those third parties, your
                data is governed by their privacy policy. Overall we do not
                accept liability arising from the operation of these third party
                privacy policies, so please check these policies carefully prior
                to activating a service.
              </p>
              <p>
                <strong>Group Companies:</strong> AirAccounting may share
                personal data with other members of our group. This includes
                AirBooks Limited, as defined in section 1159 of the UK Companies
                Act 2006.
              </p>
              <p>
                <strong>Legal and Regulatory Bodies:</strong> To the extent that
                we are duty bound by any applicable legal or regulatory
                requirement to cooperate with any competent legal or
                governmental authority or agency, we shall do so in accordance
                with applicable law. This may involve disclosure of your
                personal data and we will have no legal liability for such
                disclosures. Please note that, depending on circumstances,
                AirAccounting may be forbidden from advising you of the fact
                that your personal data has been disclosed to or requested by
                such third parties.
              </p>
              <p>
                Under Section 330 of the Proceeds of Crime 2002 we have a duty
                to report to the Serious Organised Crime Agency (SOCA) if we
                know, or have reasonable cause to suspect, that you or anyone
                connected with your business are or have been involved in money
                laundering. Failure on our part to make a report where we have
                knowledge, or reasonable grounds for suspicion, would constitute
                a criminal offence. We are obliged by law to undertake this
                reporting to SOCA but are under no obligation to make you aware
                of this reporting. In fact, we may commit the criminal offence
                of “tipping off” under Section 333 of the Proceeds of Crime Act
                2002 if we were to inform you that a report had been made. In
                consequence, neither AirAccounting principals nor staff may
                enter into any correspondence or discussions with you regarding
                such matters.
              </p>
              <p>
                <strong>Other Parties:</strong> With whom it might be necessary
                to complete a financial or corporate transaction such as a
                merger or sale of asset.
              </p>
              <p>
                <strong>Suppliers:</strong> AirAccounting works with a variety
                of third party suppliers to perform services such as website
                hosting, online product/services purchases and email
                communications. We share your personal data as necessary to
                complete any transaction or provide a product or service you
                have requested. These suppliers work on our behalf for the
                purposes described in this Policy. AirAccounting imposes strict
                contractual obligations on its suppliers to ensure data is
                secure, protected and treated in accordance with this Policy and
                we will take all steps reasonably necessary to maintain
                compliance with these obligations.
              </p>
            </div>
          </li>
          <li>
            How Can I Access and Control My Personal Data?
            <div>
              <p>
                If you want to know what personal data, we have about you, you
                can ask us for details of that personal data and for a copy of
                it (where such personal data is held). This is known as a
                “subject access request”.
              </p>
              <p>
                All subject access requests should be made in writing and sent
                by email to{' '}
                <a href="mailto:data@airaccounting.com">
                  data@airaccounting.com
                </a>{' '}
                There is not normally any charge for a subject access request.
                However, if your request is ‘manifestly unfounded or excessive’
                (for example, if you make repetitive requests) a fee may be
                charged to cover our administrative costs in responding.
              </p>
              <p>
                We will respond to your subject access request within less than
                one month of receiving it. Normally, we aim to provide a
                complete response, including a copy of your personal data within
                that time. In some cases, however, particularly if your request
                is more complex, more time may be required, up to a maximum of 3
                months from the date we receive your request. You will be kept
                fully informed of our progress.
              </p>
              <p>
                In addition to your rights under the Data Protection
                Legislation, when you submit personal data, you may be given
                options to restrict our use of your personal data. In particular
                we aim to give you strong control on our use of your data for
                direct marketing purposes, including the ability to opt-out of
                receiving emails from us which you may do by unsubscribing using
                the links provided in our emails or by contacting{' '}
                <a href="mailto:data@airaccounting.com">
                  data@airaccounting.com
                </a>
              </p>
            </div>
          </li>
          <li>
            Communication, Marketing and Advertising Preferences
            <div>
              <h3>Communication</h3>
              <p>
                You can opt out of receiving certain direct communications from
                AirAccounting.
              </p>
              <p>
                If you wish to stop receiving promotional or marketing related
                emails from us, you can do so by following the instructions
                included in every email sent to you via the unsubscribe link. We
                respect your choice, and we will stop sending you emails once
                you unsubscribe or update your preferences. Please note, it may
                take up to 14 days to process your request.
              </p>
              <p>
                If you have consented to receive communications or marketing
                from us by SMS text message, you may stop this by either
                emailing{' '}
                <a href="mailto:data@airaccounting.com">
                  data@airaccounting.com
                </a>{' '}
                or by advising us over the phone.
              </p>
              <p>
                If you have become a member, subscribed or purchased any of our
                services regardless of your preferences, we will continue to
                communicate with you for service emails, software updates,
                policy updates, or significant information about our services.
              </p>
              <p>
                You can adjust the amount of ‘interest-based advertising’ (as
                described below) you may receive by changing your cookie
                settings, changing your device settings, and/or opting out of
                certain advertising networks. If you have opted out of receiving
                emails from us, we will not use your email for interest-based
                advertising activities.
              </p>
              <h3>Cookies and Other Similar Technologies</h3>
              <p>
                We use cookies (a ‘cookie’ is a small data file that is sent to
                your computer/device cookie file when you visit a website. When
                you visit the website again the cookie allows that site to
                recognise your browser) and similar technologies like pixels,
                tags and other identifiers to help us personalise our websites
                and services for you.{' '}
              </p>
              <p>
                Our Site may place and access certain first party Cookies on
                your computer or device.
              </p>
              <p>
                First party Cookies are those placed directly by Us and are used
                only by Us. We use Cookies to facilitate and improve your
                experience of Our Site and to provide and improve Our services.
                We have carefully chosen these Cookies and have taken steps to
                ensure that your privacy and personal data is protected and
                respected at all times.
              </p>
              <p>
                By visiting our website or using our services, you agree that
                you are happy for us to set cookies and similar technologies for
                the purposes described in this Policy.
              </p>
              <h3>Cookies</h3>
              <p>We may use some or all of the following types of Cookie:</p>
              <ul>
                <li>
                  Strictly Necessary Cookies
                  <p>
                    A Cookie falls into this category if it is essential to the
                    operation of Our Site, supporting functions such as logging
                    in, your shopping basket, and payment transactions.
                  </p>
                </li>
                <li>
                  Analytics Cookies
                  <p>
                    It is important for Us to understand how you use Our Site,
                    for example, how efficiently you are able to navigate around
                    it, and what features you use. Analytics Cookies enable us
                    to gather this information, helping Us to improve Our Site
                    and your experience of it.
                  </p>
                </li>
                <li>
                  Functionality Cookies
                  <p>
                    Functionality Cookies enable Us to provide additional
                    functions to you on Our Site such as personalisation and
                    remembering your saved preferences. Some functionality
                    Cookies may also be strictly necessary Cookies, but not all
                    necessarily fall into that category.
                  </p>
                </li>
                <li>
                  Targeting Cookies
                  <p>
                    It is important for Us to know when and how often you visit
                    Our Site, and which parts of it you have used (including
                    which pages you have visited and which links you have
                    visited). As with analytics Cookies, this information helps
                    us to better understand you and, in turn, to make Our Site
                    and advertising more relevant to your interests.{' '}
                  </p>
                </li>
                <li>
                  Persistent Cookies
                  <p>
                    Any of the above types of Cookie may be a persistent Cookie.
                    Persistent Cookies are those which remain on your computer
                    or device for a predetermined period and are activated each
                    time you visit Our Site.{' '}
                  </p>
                </li>
                <li>
                  Session Cookies
                  <p>
                    Any of the above types of Cookie may be a session Cookie.
                    Session Cookies are temporary and only remain on your
                    computer or device from the point at which you visit Our
                    Site until you close your browser. Session Cookies are
                    deleted when you close your browser.
                  </p>{' '}
                </li>
              </ul>
              <p>
                By using Our Site, you may also receive certain third party
                Cookies on your computer or device. Third party Cookies are
                those placed by websites, services, and/or parties other than
                Us. Third party Cookies are used on Our Site for performance,
                functionality and advertising purposes.{' '}
              </p>
              <p>
                Before Cookies are placed on your computer or device, you will
                be shown a Cookie pop-up banner requesting your consent to set
                those Cookies. By giving your consent to the placing of Cookies
                you are enabling Us to provide the best possible experience and
                service to you. You may, if you wish, deny consent to the
                placing of Cookies unless those Cookies are strictly necessary;
                however certain features of Our Site may not function fully or
                as intended.{' '}
              </p>
              <p>
                In addition to the controls that We provide, you can choose to
                enable or disable Cookies in your internet browser. Most
                internet browsers also enable you to choose whether you wish to
                disable all Cookies or only third party Cookies. By default,
                most internet browsers accept Cookies but this can be changed.
                For further details, please consult the help menu in your
                internet browser or the documentation that came with your
                device.
              </p>
              <p>
                The links below provide instructions on how to control Cookies
                in all mainstream browsers:
              </p>
              <ul>
                <li>
                  Google Chrome:{' '}
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=en-GB"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.google.com/chrome/answer/95647?hl=en-GB
                  </a>
                </li>
                <li>
                  Microsoft Internet Explorer:{' '}
                  <a
                    href="https://support.microsoft.com/en-us/kb/278835"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.microsoft.com/en-us/kb/278835
                  </a>
                </li>
                <li>
                  Microsoft Edge:{' '}
                  <a
                    href="https://support.microsoft.com/en-gb/products/microsoft-edge"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.microsoft.com/en-gb/products/microsoft-edge
                  </a>
                </li>
                <li>
                  Safari:{' '}
                  <a
                    href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                  </a>
                </li>
                <li>
                  Safari (iOS):{' '}
                  <a
                    href="https://support.apple.com/en-gb/HT201265"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.apple.com/en-gb/HT201265
                  </a>
                </li>
                <li>
                  Mozilla Firefox:{' '}
                  <a
                    href="https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences
                  </a>
                </li>
                <li>
                  Android:{' '}
                  <a
                    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en
                  </a>
                </li>
              </ul>
              <h3>Pixels</h3>
              <p>
                We sometimes use small graphic images known as ‘pixels’ (also
                known as web beacons, clear GIFs, or pixel tags). We use pixels
                in our email, online chat and phone communications (if you have
                selected to receive such communications) to help us to
                understand how you interacted with our communication.
              </p>
              <p>
                Additionally, we also use third party pixels such as those from
                Google, Facebook, and other advertising networks, to help us
                provide advertising that is relevant to your interests.
              </p>
              <h3>Other Identifiers</h3>
              <p>
                When you use our app, we collect a unique tracking identifier
                from your device (the Advertising Identifier or “IDFA” on iOS
                devices and Google Ad ID or “AID” on Android devices) so that we
                can learn more about users of our app and provide the most
                relevant messaging and marketing. Although these identifiers are
                unique to your device, they do not contain any of your data such
                as name or email address.
              </p>
              <h3>How to control Identifier Settings</h3>
              <p>
                You can control how these identifiers are used, including the
                ability to reset them, through your device settings. You can
                learn about how to control cookie settings on Apple iOS{' '}
                <a
                  href="https://support.apple.com/en-gb/HT201265"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.apple.com/en-gb/HT201265
                </a>{' '}
                and Android{' '}
                <a
                  href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=en
                </a>
                , popular device operating systems.
              </p>
              <h3>Marketing and Advertising</h3>
              <p>
                AirAccounting use several marketing tools in support of our
                advertising efforts.
              </p>
              <p>
                In accordance with the Digital Advertising Alliance Principles,
                AirAccounting may work with third party advertisers that use
                cookies and similar technologies to provide more relevant
                advertising about our services on our website and across the
                internet.
              </p>
              <p>
                To provide this ‘interest-based advertising’, the parties
                combine non-personal data about your online activities acquired
                over time which helps cater advertising that we deliver to you.
              </p>
              <p>
                Examples of this may include an AirAccounting advertisement on a
                Facebook page, or a Google-owned page such as a search results
                page or YouTube, or on a site within Google’s advertising
                network. We do not share your data as part of this process.
              </p>
              <p>
                If you wish to opt out of interest-based advertising email{' '}
                <a href="mailto:data@airaccounting.com">
                  data@airaccounting.com
                </a>
                .
              </p>
              <p>
                If you have opted out of receiving communications from us, we
                will not use your email for interest-based advertising, although
                you may still be subject to certain interest-based advertising
                depending upon your browser and device settings.
              </p>
            </div>
          </li>
          <li>
            How and Where Do You Store or Transfer My Personal Data?
            <div>
              <p>
                By submitting your personal data, you agree to its transfer,
                storing and processing.
              </p>
              <p>
                Most personal data is stored electronically on servers or
                computer systems with controlled access and controlled
                environments.
              </p>
              <p>
                AirAccounting servers are located within enterprise-grade
                hosting facilities that employ robust physical security controls
                to prevent physical access to the servers they house. These
                controls include monitoring, 24/7 surveillance and on-site
                security staff.
              </p>
              <p>
                Other personal data may be kept in paper form within our office,
                if needed, although in the main, copies are destroyed after they
                have been scanned into computer systems.
              </p>
              <p>
                We will not transfer personal data outside the European Area
                (EEA) without complying with the provisions of the Data
                Protection Legislation in respect of such transfer. Where you
                access our services outside the EEA, it shall be your
                responsibility to ensure that any access outside of the EEA
                which results in a transfer of personal data complies with the
                provisions of the Data Protection Legislation.
              </p>
              <p>
                If you chose to access your personal data outside of the EEA,
                you should only do so in a secure environment which means that
                your browser must support the encryption security used in
                connection with our services.
              </p>
              <p>
                We may store or transfer some or all of your personal data in
                countries that are not part of the European Economic Area (the
                “EEA” consists of all EU member states, plus Norway, Iceland,
                and Liechtenstein). These are known as “third countries” and may
                not have data protection laws that are as strong as those in the
                UK and/or the EEA. This means that we will take additional steps
                in order to ensure that your personal data is treated just as
                safely and securely as it would be within the UK and under the
                Data Protection Legislation as follows:
              </p>
              <p>
                We share your data within the group of companies of which we are
                a part. Where this involves the transfer of personal data
                outside the EEA, our group ensures that personal data is
                protected by requiring all companies within the group to follow
                the same rules with respect to personal data usage. These are
                known as “binding corporate rules”. More information on binding
                corporate rules is available from the European Commission.
              </p>
              <strong className="d-block text-uppercase">and</strong>
              <p>
                Where we transfer your data to a third party based in the US,
                the data may be protected if they are part of the EU-US Privacy
                Shield. This requires that third party to provide data
                protection to standards similar to those in Europe. More
                information is available from the European Commission.
              </p>
              <p>
                Please contact us using the details in Part 1 for further
                information about the particular data protection mechanisms used
                by us when transferring your personal data to a third country.
              </p>
              <p>
                The security of your personal data is essential to us, and to
                protect your data, we take a number of important measures,
                including the following:
              </p>
              <ul>
                <li>
                  limiting access to your personal data to those employees,
                  agents, contractors, and other third parties with a legitimate
                  need to know and ensuring that they are subject to duties of
                  confidentiality;
                </li>
                <li>
                  procedures for dealing with data breaches (the accidental or
                  unlawful destruction, loss, alteration, unauthorised
                  disclosure of, or access to, your personal data) including
                  notifying you and/or the Information Commissioner’s Office
                  where we are legally required to do so.
                </li>
              </ul>
            </div>
          </li>
          <li>
            How we secure your information
            <div>
              <p>
                AirAccounting is committed to protecting the security of your
                personal data. We use a variety of security technologies and
                procedures to help protect your personal data from unauthorised
                access, use or disclosure.
              </p>
              <p>
                For example, any personal data transferred to us over the
                internet is protected with Secure Sockets Layer/Transport Layer
                Security (SSL/TLS) Encryption Certificate technology. This
                ensures that all personal data transferred is encrypted to
                prevent eavesdropping and tampering.
              </p>
              <p>
                While no service is completely secure, AirAccounting takes
                precautionary measures and has strict security standards to help
                prevent data loss, theft, misuse and unauthorised access,
                disclosure, alteration and destruction or other like incidents
                that might affect the security of your personal data.
              </p>
              <p>
                These measures include encryption, password protection,
                anti-malware, firewalls, server authentication, user profiles,
                backup/disaster recovery systems, restriction of access to
                premises and computer systems, as well as use of relevant
                third-party service providers to provide security.
              </p>
              <p>
                AirAccounting imposes strict contractual obligations on its
                suppliers, subcontractors and partners to ensure data is secure,
                protected and treated in accordance with this Policy and we will
                take all steps reasonably necessary to maintain compliance with
                these obligations.
              </p>
              <p>
                However, we cannot guarantee the security of your personal data
                while it is being transmitted to us if you do not enter or
                import it from a secure environment or secure mobile device.
              </p>
              <p>
                Our services can be accessed through the use of an individual
                user login and password. To protect the confidentiality of data,
                you must keep your password confidential and not disclose it to
                any other person. Please alert us immediately if you believe
                your password has been misused. Additionally, always logout and
                close your browser when you finish your session, especially if
                you’re on a public computer. You are ultimately responsible for
                administering and safeguarding any passwords created to control
                access. Please note, we will never ask you to disclose your
                password in an unsolicited email or phone call.
              </p>
              <p>
                AirAccounting contracts with Openwrks, a data platform,
                authorised and regulated by the FCA under reference number
                709589. We use OpenWrks to enable you to share your financial
                information with us securely and directly from your bank account
                so we can understand your income and expenditure without you
                having to manually upload your bank statements. This service
                provides an efficient method of transferring receipt and payment
                details directly from your bank statements to your accounting
                records and Openwrks security protocols are applied to the
                transmissions. This service does not give AirAccounting or any
                third parties the ability to transact via the relevant bank
                accounts. OpenWrks' terms and conditions can be found here.
                https://www.openwrks.com/terms-and-conditions
              </p>
              <p>
                {' '}
                If you have any questions about the security of your data,
                please contact us at{' '}
                <a href="mailto:data@airaccounting.com">
                  data@airaccounting.com
                </a>
                .
              </p>
            </div>
          </li>
          <li>
            How Long Will You Keep My Personal Data?
            <div>
              <p>
                AirAccounting will not keep your personal data for any longer
                than is necessary to provide our services or for other essential
                purposes such as complying with our legal obligations, resolving
                disputes and enforcing our agreements. As these needs can vary
                for different data types in the context of different services,
                actual retention periods can vary significantly.
              </p>
              <p>
                As an example, the default standard retention period for
                accounting records is 6 years plus current, otherwise known as 6
                years + one. This is defined as 6 years after the last entry in
                a record followed by first review and/or destruction to be
                carried out in additional current (+ one) accounting year.
              </p>
              <p>
                If AirAccounting need to alter, restrict processing of your
                data, or remove your data, we will inform you.
              </p>
            </div>
          </li>
          <li>
            What Are My Rights?
            <div>
              <p>
                Under the Data Protection Legislation, you have the following
                rights, which AirAccounting will always work to uphold:
              </p>
              <ul className="letters">
                <li>
                  The right to be informed about our collection and use of your
                  personal data. This Privacy Policy should tell you everything
                  you need to know, but you can always contact us to find out
                  more or to ask any questions by emailing{' '}
                  <a href="data@airaccounting.com">data@airaccounting.com</a>
                </li>
                <li>
                  The right to access the personal data we hold about you. Part
                  7 tells you how to do this.{' '}
                </li>
                <li>
                  The right to have your personal data rectified if any of your
                  personal data held by us is inaccurate or incomplete. Please
                  contact us using the details in Part 1 to find out more.
                </li>
                <li>
                  The right to be forgotten, i.e. the right to ask us to delete
                  or otherwise dispose of any of your personal data that we
                  hold. Please contact us using the details in Part 1 to find
                  out more.
                </li>
                <li>
                  The right to restrict (i.e. prevent) the processing of your
                  personal data.
                </li>
                <li>
                  The right to object to processing based on legitimate
                  interests or the performance of a task in the public
                  interest/exercise of official authority (including profiling);
                  direct marketing; and processing for purposes of
                  scientific/historical research and statistics. In relation to
                  AirAccounting, only the direct marketing ground is applicable.
                  If you wish to object to AirAccounting direct marketing, email{' '}
                  <a href="data@airaccounting.com">data@airaccounting.com</a>{' '}
                </li>
                <li>
                  The right to withdraw consent. This means that, if we are
                  relying on your consent as the legal basis for using your
                  personal data, you are free to withdraw that consent at any
                  time.
                </li>
                <li>
                  The right to data portability. This means that, if you have
                  provided personal data to us directly, we are using it with
                  your consent or for the performance of a contract, and that
                  data is processed using automated means, you can ask us for a
                  copy of that personal data to re-use with another service or
                  business in many cases.
                </li>
                <li>
                  Rights relating to automated decision-making and profiling. If
                  you exercise any of your rights and your personal data has
                  been shared with third parties, AirAccounting will notify the
                  third parties that you are exercising your rights as relevant
                  to them.{' '}
                </li>
              </ul>
              <p>
                For more information about our use of your personal data or
                exercising your rights as outlined above, please contact us
                using the details provided in Part 1.
              </p>
              <p>
                It is important that your personal data is kept accurate and up
                to date. If any of the personal data we hold about you changes,
                please keep us informed as long as we have that data.
              </p>
              <p>
                Further information about your rights can also be obtained from
                the Information Commissioner’s Office or your local Citizens
                Advice Bureau.
              </p>
              <p>
                If you have any cause for complaint about our use of your
                personal data, you have the right to lodge a complaint with the
                Information Commissioner’s Office. We would welcome the
                opportunity to resolve your concerns ourselves, however, so
                please contact us first, using the details in Part 1.
              </p>
            </div>
          </li>
          <li>
            Changes to our Policy
            <div>
              <p>
                AirAccounting may modify or update this Policy as and when
                necessary to reflect feedback and changes in our services.
                Visiting our website and/or using our services after any
                modification of this Policy will constitute your acceptance of
                such modification and updates.
              </p>
              <p>
                If there are material changes to the Policy or in how Air
                Accounting uses your personal data, we will notify you either by
                posting a notice of such changes before they take effect or by
                directly sending you a notification. We encourage you to
                regularly review this Policy to learn more about how Air
                Accounting is using and protecting your data.
              </p>
            </div>
          </li>
        </ol>
      </section>
    </Container>
  </SiteLayout>
)

export default TermsOfUse
